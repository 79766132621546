<template>
	<div class="home">

		<v-card>
			<v-container fluid pt-0 mt-0>
				<v-row class="blue-grey darken-4">
                    <Breadcrumbs/>
                </v-row>
				<v-row><v-col cols="12"></v-col></v-row>
            
                <v-row>
                    <v-col cols="12" sm="12" md="4" lg="3" xl="3">

                        <v-autocomplete label="Filter Broker" 
                            v-model="selectedDeveloper" :items="listDeveloper" item-text="text" item-value="value"
                            auto-select-first chips clearable deletable-chips small-chips></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                        <v-autocomplete label="Filter Book" 
                            v-model="selectedProject" :items="listProject" item-text="text" item-value="value"
                            auto-select-first chips clearable deletable-chips multiple small-chips></v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                        <v-text-field label="Suburb" 
                            v-model="selectedSuburb" hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                        <v-text-field label="DDL ID NO" 
                            v-model="selectedDdlIdNo" hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                        <v-text-field label="iNNi User ID" 
                            v-model="selectedUserId" hide-details="auto"></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                        <v-text-field label="Email" 
                            v-model="selectedEmail" hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                        <v-text-field label="First Name" 
                            v-model="selectedFirstName" hide-details="auto"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="3" xl="3">
                        <v-text-field label="Last Name" 
                            v-model="selectedLastName" hide-details="auto"></v-text-field>
                    </v-col>

                </v-row>
                
                <v-row>
                    <v-col cols="4">
                    
                    </v-col>
                </v-row>
                
                <v-row>
                    <v-col cols="4"> 
                        <v-btn  v-on:click="fetchTradeRecord()" :loading="btnLoading" color="primary">SEARCH</v-btn>
                    </v-col>
                </v-row>
                
                <v-row>    
                    <v-col lg="11" md="11" xs="12" cols="12">
                        <v-text-field 
                            class="mr-4 mt-n2"
                            v-model="search"
                            append-icon="mdi-search"
                            label="Search"
                            single-line
                            hide-details></v-text-field>
                    </v-col>
                    <v-col lg="1" md="1" xs="12" cols="12">
                        <download-excel :data="tradesList" :name="'Search'">
                            <v-btn color="#08591c" class="accent-4">To CSV</v-btn>
                        </download-excel>
                    </v-col>
                </v-row>
               
                <v-row>
                    <v-col cols="12">
                        <v-card flat>
                            <DataTable :headers="headers" :dataSource="tradesList" :search="search"/>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import * as apiBook from "@components/api/pk/book";
import * as apiGroup from "@components/api/pk/group";
import * as apiSymbol from "@components/api/pk/symbol";
import * as apiTrade from "@components/api/pk/trades";
import DataTable from '@components/search/DataTable'
import moment from "moment";
moment.locale('en-au');
import Breadcrumbs from "@components/Breadcrumbs";
import {mapActions, mapState} from 'vuex';

export default {
    components:{
        DataTable, Breadcrumbs
    },
    data () {
        return {
            listDeveloper: [
                { text: "Poly Mel", value: "Poly Mel"},
                { text: "R&F", value: "R&F"},
                { text: "Centurion", value: "Centurion"},
                { text: "Positive", value: "Positive"},
            ],
            listProject: [
                { text: "WWP", value: "WWP" },
                { text: "LCDT", value: "LCDT" },
                { text: "RDS", value: "RDS" },
                { text: "SCOFLD", value: "SCOFLD" },
                { text: "BKM HILL", value: "BKM HILL" },
                { text: "CastleH", value: "CastleH" },
            ],
            selectedDeveloper: "Poly Mel",
            selectedProject: "WWP",
            selectedSuburb: "",
            selectedDdlIdNo: -1,
            selectedUserId: null,
            selectedEmail: "",
            selectedFirstName: "",
            selectedLastName: "",

            btnLoading:false,
            selectedOffsetStart: 0,
            selectedLimit: 100,
            selectedOrderBy: "open_time",
            orderByLists: ["ticket","login","open_time","close_time","profit"],
            isAsc: false,
            searchloading: false,
            selectedLogin: "",
            selectedCmd: [],
            selectedReason: [],
            selectedBrokerId: 1,
            selectedGroup: [],
            selectedBook: [],
            selectedSymbol: "",
            selectedOpenTimeStart: null,
            selectedOpenTimeEnd: null,
            selectedCloseTimeStart: null,
            selectedCloseTimeEnd: null,
            selectedIsOpen: false,
            toggleCloseFilter: true,
            filterOrderBy: ["close_time", "open_time", "login"],
            filterGroupLists: [],
            filterSymbolLists: [],
            filterBookLists: [],
            selectedPendingOrMarket: "market",

            filterCmdLists: [{name: "Buy", cmd: 0},{name: "Sell", cmd: 1}],

            filterCmdMarket: [{name: "Buy", cmd: 0},{name: "Sell", cmd: 1}],
            filterCmdPending: [{name: "Buy Limit", cmd: 2},{name: "Sell Limit", cmd: 3},{name: "Buy Stop", cmd: 4},{name: "Sell Stop", cmd: 5}],
            
            filterReasonLists: [
                { value: 0, name: 'CLIENT' },
                { value: 1, name: 'EXPERT' },
                { value: 2, name: 'DEALER' },
                { value: 3, name: 'SIGNAL' },
                { value: 4, name: 'GATEWAY' },
                { value: 5, name: 'MOBILE' },
                { value: 6, name: 'WEB' },
                { value: 7, name: 'API' },
            ],

            tradesList: [],
            csvHeader:{
                book:'Book',
                login:'Login',
                ticket:'Ticket',
                symbol:'Symbol',
                cmd:'Type',
                volume:'Volume',
                open_time:'OpenTime',
                open_price:'OpenPrice',
                close_time:'CloseTime',
                close_price:'ClosePrice',
                profit:'Profit',
                sl:'Sl',
                tp:'Tp',
                swaps:'Swaps',
                commission:'Commission',
                comment:'Comment',
                reason:'Reason',
            },
            tradesSymbol: [],

            search:"",
            headers:[
                { text: 'Book', value: 'book' },
                { text: 'Login', value: 'login' },
                { text: 'Ticket', value: 'ticket' },
                { text: 'Symbol', value: 'symbol' },
                { text: 'Type', value: 'cmd' },
                { text: 'Volume', value: 'volume' },
                { text: 'Open Time', value: 'open_time' },
                { text: 'Open Price', value: 'open_price' },
                { text: 'Close Time', value: 'close_time' },
                { text: 'Close Price', value: 'close_price' },
                { text: 'Profit', value: 'profit' },
                { text: 'SL', value: 'sl' },{ text: 'TP', value: 'tp' },
                { text: 'Swaps', value: 'swaps' },
                { text: 'Commission', value: 'commission' },
                { text: 'Comment', value: 'comment' }, 
                { text: 'Reason', value: 'reason' },
            ],
            brokers: [],
            root: 0,
        }
    },
    watch: {
        selectedBrokerId(nv, ov) {
            this.loadBook();
            this.loadGroup();
        },
        selectedPendingOrMarket(nv, ov) {
            if (nv == "market") {
                this.filterCmdLists = this.filterCmdMarket;
            }
            if (nv == "pending") {
                this.filterCmdLists = this.filterCmdPending;
            }
        },  
        selectedIsOpen(nv, ov) {
            console.log(nv);
            if (nv == true) {
                this.selectedCloseTimeStart = null;
                this.selectedCloseTimeEnd = null;
                this.toggleCloseFilter = false;
            } else {
                this.toggleCloseFilter = true;
            }
        },
        selectedSymbol(nv, ov) {
            console.log(nv);
        },
        selectedBook(nv, ov) {
            console.log(nv);
        },
        selectedCmd(nv, ov) {
            console.log(nv);
        },
        selectedReason(nv, ov) {
            console.log(nv);    
        },
        selectedGroup(nv, ov) {
            console.log(nv);
        },
        selectedLogin(nv, ov) {
            console.log(nv)
        }, 
        // selectedMaxVolume(nv, ov) {
        //     console.log(nv)
        // }, 
        // selectedMinVolume(nv, ov) {
        //     console.log(nv)
        // },
        selectedOpenTime(nv, ov) {
            console.log(nv);
        },
        selectedOpenTimeStart(nv, ov) {
            console.log(nv)
        },
        selectedOpenTimeEnd(nv, ov) {
            console.log(nv)
        },
        selectedCloseTimeStart(nv, ov) {
            console.log(nv)
        },
        selectedCloseTimeEnd(nv, ov) {
            console.log(nv)
        }
    },
    // computed: {
    //     ...mapState([
    //         'userLevel',
    //         'brokerId'
    //     ])
    // },
    methods: {
        changeView(key) {

        },
        toCSV () {
            // this.$ToCSV("SearchResult",this.csvHeader,this.tradesList)
        },
        /**
         * Fetch Symbol Lists
         * @return {[type]}
         */
        async fetchSymbol() {
            let self = this;
            
            apiSymbol.fetch().then(res => {
                let data = res.data.data;
                self.filterSymbolLists = data;
            })
        },
        /**
         * Fetch users book group mapping
         * @return {[type]}
         */
        async loadBook() {
            let self = this;
            let params = {broker_id: this.selectedBrokerId};
            apiBook.fetch(params).then(res => {
                // let data = res.data.data;
                console.log(res);
                self.filterBookLists = res.data;
                if (res.data.length != 0) {
                    // self.selectedBook = res.data[0].book;
                }
            })
        },
        /**
         * Fetch users book group mapping
         * @return {[type]}
         */
        async loadGroup() {
            let self = this;
            let params = {broker_id: this.selectedBrokerId};
            apiGroup.fetch(params).then(res => {
                // let data = res.data.data;
                console.log(res);
                self.filterGroupLists = res.data;
            })
        },
        async fetchTradeRecord() {
            let self = this;
            
            let finalFilterSymbol = [];

            if (self.selectedSymbol != "" && self.selectedSymbol != undefined && self.selectedSymbol != null) {
                finalFilterSymbol.push(self.selectedSymbol);
                for (let i = 0; i < this.$store.state.symbolEndfix.length; i++) {
                    let endFixSymbol = "";
                    if (this.$store.state.symbolEndfix[i].indexOf("-") != -1) {
                        endFixSymbol = self.selectedSymbol + this.$store.state.symbolEndfix[i].toUpperCase();
                    } else {
                        endFixSymbol = self.selectedSymbol + this.$store.state.symbolEndfix[i].toLowerCase();
                    }
                    finalFilterSymbol.push(endFixSymbol);
                }
            }

            let params = {
                selectedPendingOrMarket: self.selectedPendingOrMarket,
                broker_id: self.selectedBrokerId,
                selectedLogin: self.selectedLogin,
                selectedCmd: self.selectedCmd,
                selectedReason: self.selectedReason,
                selectedGroup: self.selectedGroup,
                selectedBook: self.selectedBook,
                selectedSymbol: finalFilterSymbol,
                selectedOpenTimeStart: self.selectedOpenTimeStart,
                selectedOpenTimeEnd: self.selectedOpenTimeEnd,
                selectedCloseTimeStart: self.selectedCloseTimeStart,
                selectedCloseTimeEnd: self.selectedCloseTimeEnd,
                selectedIsOpen: self.selectedIsOpen,
                selectedLimit: self.selectedLimit,
                selectedOffsetStart: self.selectedOffsetStart,
                selectedOrderBy: self.selectedOrderBy,
                isAsc: self.isAsc
            };
            self.btnLoading = true;
            console.log(params);
            apiTrade.create(params).then(res => {
                let data = res.data;
                self.tradesList = data;
                // console.log(self.tradesList);
                self.btnLoading = false;
            })
        },
        remove (item) {
            this.chips.splice(this.chips.indexOf(item), 1)
            this.chips = [...this.chips]
        },
    },
    created() {},
    mounted() {}
}
</script>